/* eslint-disable max-lines */
import { Component } from "react";
import { Navigate, NavLink } from "react-router-dom";
import BootstrapTable, { SelectRowProps } from "react-bootstrap-table-next";
import filterFactory, { customFilter } from "react-bootstrap-table2-filter";
import {
  ODataEdmType,
  IODataMetadata,
  IODataEntitySet,
  IODataEntityType,
} from "../../models";
import { fixInvalidIdentifierCharacter } from "./Wizard/SelectTables";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  InputProps,
  Label,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";

import { Form } from "react-bootstrap";

import Loader from "../../components/Loader";
import { CDataComponent } from "../../utility/CDataComponent";
import { IModalFunctions } from "../../layouts/Dashboard";
import PromptWithModal from "../../components/PromptWithModal";
import { ToastrError, ToastrSuccess } from "../../services/toastrService";
import { RequestType, IAPI, withAPI } from "../../components/withAPI";
import {
  withRouter,
  ComponentWithRouterProps,
} from "../../components/withRouter";
import CustomTextFilter from "../../components/CustomTextFilter";
import { store } from "../../redux/store";

function columnNameFormatter(cell: any, row: any, key: any) {
  const keyIcon =
    cell === key ? (
      <>
        <div id="keyIcon" className="d-inline">
          <i className="fa fa-key fa-sm align-middle mb-1 no-pointer-event" />
        </div>
        <UncontrolledTooltip placement="top" target="keyIcon">
          Primary Key
        </UncontrolledTooltip>
      </>
    ) : null;
  //const editDot = row.edited ? <span className="dot" /> : null;

  return (
    <>
      {cell}
      {keyIcon}
    </>
  );
}

function editRowFormatter(cell: any, row: any) {
  const editDot = row.edited ? (
    <>
      <span id="dot" className="dot" />
      <UncontrolledTooltip placement="top" target="dot">
        Column has been edited with custom overrides
      </UncontrolledTooltip>
    </>
  ) : null;

  return (
    <Col className="text-end ps-0">
      {editDot}
      <i className="fa-regular fa-pen align-middle me-2"></i>
    </Col>
  );
}

type IEditTableProps = {
  odataMetadata: IODataMetadata;
  fullName: string;
  name: string;
} & IModalFunctions &
  IAPI &
  ComponentWithRouterProps;

interface IEditTableState {
  odataMetadata: IODataMetadata;
  fullName: string;
  name: string;
  key: string;
  allTableProperties: any[];
  allTablePropertiesEdited: any[];
  selectedColumnIds: any[];
  currentlySelectedColumn: any;
  previouslySelectedColumn: any;
  tableAliasIsInvalid: boolean;
  tableAliasIsInvalidMessage: string;
  columnAliasIsInvalid: boolean;
  columnAliasIsInvalidMessage: string;
  processingRequest: boolean;
  returnToUsers: boolean;
  saveButtonDisabled: boolean;
  unsavedChanges: boolean;
  loading: boolean;
}

class EditTable
  extends Component<IEditTableProps, IEditTableState>
  implements CDataComponent
{
  constructor(props: IEditTableProps) {
    super(props);
    this.updateAlias = this.updateAlias.bind(this);
    this.updateODataModel = this.updateODataModel.bind(this);
    this.updateColumns = this.updateColumns.bind(this);
    this.state = {
      odataMetadata: (props.router.location.state as any)?.odataMetadata,
      fullName: (props.router.location.state as any)?.fullName,
      name: (props.router.location.state as any)?.name,
      key: "",
      allTableProperties: [],
      allTablePropertiesEdited: [],
      selectedColumnIds: [],
      currentlySelectedColumn: {},
      previouslySelectedColumn: null,
      tableAliasIsInvalid: false,
      tableAliasIsInvalidMessage: "",
      columnAliasIsInvalid: false,
      columnAliasIsInvalidMessage: "",
      processingRequest: false,
      returnToUsers: false,
      saveButtonDisabled:
        (store.getState().usage.connectionsOverLimit ||
          store.getState().usage.dataSourcesOverLimit) ??
        false,
      unsavedChanges: false,
      loading: true,
    };
  }

  invalidOdataIdentifierRegex =
    /^[_\p{L}\p{Nl}][_\p{L}\p{Nl}\p{Nd}\p{Mn}\p{Mc}\p{Pc}\p{Cf}]{0,127}$/u;
  invalidOdataIdentifierErrorMessage =
    "Alias must start with an alphabetical character, underscore, or certain Unicode characters, and consist of up to 127 alphanumeric, underscore, and specified Unicode characters.";

  public getComponentName() {
    return "pages-odata-EditTable";
  }
  async componentDidMount() {
    await this.getColumns();
    this.setState({ loading: false });
    if (!(this.props.router.location.state as any)?.odataMetadata) {
      this.props.router.navigate("/odata");
    }
  }

  async getColumns(): Promise<any> {
    const metadata = this.state.odataMetadata;
    if (!metadata) return;

    const tableIndex = metadata.dataServices!.schema!.entityTypes!.findIndex(
      (table: IODataEntityType) =>
        table.fullName === this.state.fullName &&
        table.name === this.state.name,
    );
    const fullNameArray =
      metadata.dataServices!.schema!.entityTypes![tableIndex]!.fullName!.split(
        ".",
      );
    const catalogName = fullNameArray[0];
    const schemaName = fullNameArray[1];
    const tableName = fullNameArray[2];

    const searchParams = new URLSearchParams({
      catalogName: catalogName,
      schemaName: schemaName,
      tableName: tableName,
    });
    const { status, payload } = await this.props.callAPI(
      RequestType.Get,
      `/columns?${searchParams}`,
      "",
    );
    if (status === 200) {
      if (!payload.error) {
        // Get available Columns
        const schema = payload.results[0].schema;
        const rows = payload.results[0].rows;

        const nameIndex = schema.findIndex(
          (property: any) => property.columnName === "COLUMN_NAME",
        );
        const typeIndex = schema.findIndex(
          (property: any) => property.columnName === "TYPE_NAME",
        );
        const isNullableIndex = schema.findIndex(
          (property: any) => property.columnName === "IS_NULLABLE",
        );
        const maxLengthIndex = schema.findIndex(
          (property: any) => property.columnName === "COLUMN_SIZE",
        );
        const isKeyIndex = schema.findIndex(
          (property: any) => property.columnName === "IS_KEY",
        );
        const allTableProperties = rows.map((row: any) => {
          const columnAlias: string = row[nameIndex].replace(/#|&|\?|\./g, "_");

          if (row[isKeyIndex]) {
            this.setState({ key: row[nameIndex] });
          }

          if (
            ODataEdmType[row[typeIndex].toUpperCase() as keyof ODataEdmType] ===
            ODataEdmType.STRING
          ) {
            return {
              columnName: row[nameIndex],
              name: columnAlias,
              type:
                ODataEdmType[
                  row[typeIndex].toUpperCase() as keyof ODataEdmType
                ] ?? ODataEdmType.STRING,
              nullable: row[isNullableIndex],
              maxLength: row[maxLengthIndex],
            };
          } else {
            return {
              columnName: row[nameIndex],
              name: columnAlias,
              type:
                ODataEdmType[
                  row[typeIndex].toUpperCase() as keyof ODataEdmType
                ] ?? ODataEdmType.STRING,
              nullable: row[isNullableIndex],
            };
          }
        });

        // Get selected columns
        const selectedTable =
          metadata.dataServices!.schema!.entityTypes!.filter((table) => {
            return (
              table.fullName === this.state.fullName &&
              table.name === this.state.name
            );
          });
        const selectedTableProperties = selectedTable[0].properties;

        // Convert array of selected columns to string array for consumption in Bootstrap Table selectRow
        const selected = selectedTableProperties!.map((property) => {
          return property.columnName;
        });

        // Get properties that have been updated previously
        const allTablePropertiesEdited = allTableProperties.map(
          (driverProp: any) => {
            const result = selectedTableProperties!.find(
              (updatedProp) => updatedProp.columnName === driverProp.columnName,
            );
            if (
              result &&
              (result.name !== driverProp.columnName ||
                result.name !== driverProp.name ||
                result.type !== driverProp.type ||
                result.nullable !== driverProp.nullable ||
                result.maxLength !== driverProp.maxLength)
            ) {
              return { ...result, edited: true };
            }
            return { ...driverProp, edited: false };
          },
        );
        this.setState({
          allTableProperties: allTableProperties,
          allTablePropertiesEdited: allTablePropertiesEdited,
          selectedColumnIds: selected,
        });
      } else {
        this.props.showErrorModal(
          "Failed to get columns due to the following error:",
          payload.error,
        );
      }
    }
  }

  isHeaderCheckboxIndeterminate = () => {
    const allTableProperties = this.state.allTableProperties;
    const selectedColumnIds = this.state.selectedColumnIds;
    const element = document.getElementById(
      "checkAllTables",
    ) as HTMLInputElement;
    if (element) {
      if (
        selectedColumnIds.length > 0 &&
        selectedColumnIds.length < allTableProperties.length
      ) {
        element.indeterminate = true;
      } else {
        element.indeterminate = false;
      }
    }
  };

  checkForDuplicateAlias(alias: string) {
    const entitySets =
      this.state.odataMetadata.dataServices!.schema!.entityContainer!
        .entitySets;
    const duplicates = entitySets!.filter(
      (table: IODataEntitySet) =>
        table.name.toLowerCase() === alias.toLowerCase(),
    );
    return duplicates.length > 0;
  }

  updateAlias(event: any) {
    const metadata = this.state.odataMetadata;
    const alias = event.target.value;
    if (alias < 1 || alias > 100) {
      this.setState({
        tableAliasIsInvalid: true,
        tableAliasIsInvalidMessage:
          "Alias name must be between 1 and 100 characters",
      });
    } else if (this.checkForDuplicateAlias(alias)) {
      this.setState({
        tableAliasIsInvalid: true,
        tableAliasIsInvalidMessage:
          "Alias name must be unique across connections",
      });
    } else if (!this.invalidOdataIdentifierRegex.test(alias)) {
      this.setState({
        tableAliasIsInvalid: true,
        tableAliasIsInvalidMessage: this.invalidOdataIdentifierErrorMessage,
      });
    } else {
      this.setState({
        tableAliasIsInvalid: false,
        tableAliasIsInvalidMessage: "",
      });
    }

    const tableIndex = metadata.dataServices!.schema!.entityTypes!.findIndex(
      (table) =>
        table.fullName === this.state.fullName &&
        table.name === this.state.name,
    );
    metadata.dataServices!.schema!.entityTypes![tableIndex].name = alias;
    metadata.dataServices!.schema!.entityContainer!.entitySets![
      tableIndex
    ].name = alias;
    metadata.dataServices!.schema!.entityContainer!.entitySets![
      tableIndex
    ].entityType = `CData.${alias}`;
    this.setState({
      odataMetadata: metadata,
      name: alias,
      unsavedChanges: true,
    });
  }

  updateODataModel(event: any) {
    const currentlySelectedColumn = this.state.currentlySelectedColumn;
    const { name, value } = event.target;
    if (name === "columnAlias") {
      const alias = value;
      currentlySelectedColumn.name = alias;
      if (!this.invalidOdataIdentifierRegex.test(alias)) {
        this.setState({
          columnAliasIsInvalid: true,
          columnAliasIsInvalidMessage: this.invalidOdataIdentifierErrorMessage,
        });
      } else {
        this.setState({
          columnAliasIsInvalid: false,
          columnAliasIsInvalidMessage: "",
        });
      }
    } else if (name === "maxLength") {
      currentlySelectedColumn.maxLength = value ? parseInt(value) : "";
    } else if (name === "nullable") {
      currentlySelectedColumn.nullable = !currentlySelectedColumn.nullable;
    } else if (name === "type" && value !== "Edm.String") {
      currentlySelectedColumn[name] = value;
      delete currentlySelectedColumn.maxLength;
    } else {
      currentlySelectedColumn[name] = value;
    }
    currentlySelectedColumn.edited = true;
    const allTablePropertiesEdited = this.state.allTablePropertiesEdited;
    const propertyIndex = allTablePropertiesEdited.findIndex(
      (property) => property.name === currentlySelectedColumn.name,
    );
    allTablePropertiesEdited[propertyIndex] = currentlySelectedColumn;

    this.setState({
      currentlySelectedColumn: currentlySelectedColumn,
      allTablePropertiesEdited: allTablePropertiesEdited,
      unsavedChanges: true,
    });
  }

  async updateColumns(event: any) {
    event.preventDefault();
    const metadata = this.state.odataMetadata;
    const allTablePropertiesEdited = this.state.allTablePropertiesEdited;
    // Convert selectColumns string array back to array of property objects
    const selectedColumnIds = this.state.selectedColumnIds;
    const selectedColumns = allTablePropertiesEdited.filter((property) =>
      selectedColumnIds.includes(property.columnName),
    );
    const tableIndex = metadata.dataServices!.schema!.entityTypes!.findIndex(
      (table) =>
        table.fullName === this.state.fullName &&
        table.name === this.state.name,
    );
    metadata.dataServices!.schema!.entityTypes![tableIndex].properties =
      selectedColumns;

    if (this.state.key && selectedColumnIds.includes(this.state.key)) {
      metadata.dataServices!.schema!.entityTypes![tableIndex].key = {
        propertyRefs: [{ name: this.state.key }],
      };
    } else {
      metadata.dataServices!.schema!.entityTypes![tableIndex].key = undefined;
    }

    this.setState({ odataMetadata: metadata, processingRequest: true });

    const responsePayload = await this.props.callAPI(
      RequestType.Post,
      "/odata/writeToBlob",
      "",
      metadata,
    );
    if (responsePayload.status === 200) {
      ToastrSuccess(
        "Changes successfully saved",
        "Your OData changes has been successfully saved.",
      );
    } else {
      const data = responsePayload.error;
      const error = JSON.parse(data);
      ToastrError("Failed to Save Changes", error);
    }

    this.props.toggleModal();
    this.setState({ processingRequest: false, unsavedChanges: false });
  }

  renderEditTableCard() {
    const metadata = this.state.odataMetadata;
    if (!metadata) {
      return;
    }
    const key = this.state.key;
    const allTablePropertiesEdited = this.state.allTablePropertiesEdited;

    const tableIndex = metadata.dataServices!.schema!.entityTypes!.findIndex(
      (table: IODataEntityType) =>
        table.fullName === this.state.fullName &&
        table.name === this.state.name,
    );
    let tableName = this.state.fullName.split(".")[2];
    if (tableName.includes(" ")) {
      tableName = tableName.replace(/\s+/g, "_");
    }
    tableName = fixInvalidIdentifierCharacter(tableName);
    const catalogName = this.state.fullName.split(".")[0];
    const entityType = catalogName === "CData" ? "View Name" : "Table Name";
    const entityTypeColumns =
      catalogName === "CData" ? "View Columns" : "Table Columns";
    const alias = String(
      metadata.dataServices!.schema!.entityTypes![tableIndex].name,
    );

    const propertyColumns = [
      {
        dataField: "columnName",
        text: "",
        sort: false,
        hidden: true,
      },
      {
        dataField: "name",
        text: "Table Columns",
        sort: false,
        formatter: (row: any, cell: any) => columnNameFormatter(row, cell, key),
        filter: customFilter({}),
        filterRenderer: (onFilter: (value: string) => void, column: any) => (
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            placeholder="Search by name..."
          />
        ),
      },
      {
        dataField: "type",
        text: "",
        sort: false,
        hidden: true,
      },
      {
        dataField: "nullable",
        text: "Is Nullable",
        sort: false,
        hidden: true,
      },
      {
        dataField: "maxLength",
        text: "Max Length",
        sort: false,
        hidden: true,
      },
      {
        dataField: "edited",
        text: "",
        sort: false,
        formatter: (row: any, cell: any) => editRowFormatter(row, cell),
      },
    ];

    const selectTableListRow: SelectRowProps<any> = {
      mode: "checkbox",
      clickToSelect: true,
      selected: this.state.selectedColumnIds,
      selectColumnStyle: () => {
        return {
          paddingTop: "0",
          paddingBottom: "0",
        };
      },
      selectionRenderer: (props) => {
        return (
          <>
            <div>
              <Input
                type={props.mode as InputProps["type"]}
                checked={props.checked}
              />
            </div>
          </>
        );
      },
      selectionHeaderRenderer: (props) => (
        <Input
          id="checkAllTables"
          type={props.mode as InputProps["type"]}
          ref={() => this.isHeaderCheckboxIndeterminate()}
          checked={props.checked}
        />
      ),
      onSelect: (row, isSelect, rowIndex, event) => {
        if (event.currentTarget instanceof HTMLTableCellElement) {
          if (isSelect) {
            this.setState({
              selectedColumnIds: [
                ...this.state.selectedColumnIds,
                row.columnName,
              ],
              unsavedChanges: true,
            });
          } else {
            this.setState({
              selectedColumnIds: this.state.selectedColumnIds.filter(
                (columnId: string) => columnId !== row.columnName,
              ),
              unsavedChanges: true,
            });
          }
        } else {
          const previouslySelectedRow = document.getElementById(
            "previouslySelectedRow",
          )!;
          if (previouslySelectedRow) {
            previouslySelectedRow.className = "";
            previouslySelectedRow.id = "";
          }
          event.currentTarget.className = "odata-column-selection-row";
          event.currentTarget.id = "previouslySelectedRow";
          this.setState({ currentlySelectedColumn: row });
          return false;
        }
      },
      onSelectAll: (isSelect, rows) => {
        if (isSelect) {
          this.setState({
            selectedColumnIds: rows.map((row) => {
              return row.columnName;
            }),
            unsavedChanges: true,
          });
        } else {
          this.setState({ selectedColumnIds: [], unsavedChanges: true });
        }
      },
    };
    const columnTable = (
      <BootstrapTable
        bootstrap4
        id="odataTableList"
        classes="odata-columns-table mt-n2"
        bordered={false}
        keyField="columnName"
        columns={propertyColumns}
        data={allTablePropertiesEdited}
        selectRow={selectTableListRow}
        filter={filterFactory()}
      />
    );

    const currentlySelectedColumn = this.state.currentlySelectedColumn;

    let dataTypes = Object.keys(ODataEdmType).map((type) => {
      return ODataEdmType[type as keyof ODataEdmType];
    });
    dataTypes = [...new Set(dataTypes)];

    const dataTypeDropdownOptions = dataTypes.map((type) => {
      return (
        <option key={type} value={type}>
          {type}
        </option>
      );
    });

    const maxLengthField =
      currentlySelectedColumn.type === "Edm.String" ? (
        <Form.Group className="mb-3">
          <Label>Max Length</Label>
          <Form.Control
            name="maxLength"
            value={currentlySelectedColumn.maxLength}
            type="text"
            onChange={this.updateODataModel}
          />
        </Form.Group>
      ) : null;

    const editODataColumnBody =
      Object.keys(this.state.currentlySelectedColumn).length !== 0 ? (
        <>
          Primary Key
          <Input
            checked={currentlySelectedColumn.name === this.state.key}
            type="switch"
            className="odata-checkbox mb-3"
            disabled
          />
          <Form.Group className="mb-3">
            <Label>Full Name</Label>
            <Form.Control
              disabled
              name="columnName"
              value={fixInvalidIdentifierCharacter(
                currentlySelectedColumn.columnName,
              )}
              type="text"
              onChange={this.updateODataModel}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Label>Alias</Label>
            <Form.Control
              name="columnAlias"
              value={currentlySelectedColumn.name}
              type="text"
              onChange={this.updateODataModel}
              isInvalid={this.state.columnAliasIsInvalid}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.columnAliasIsInvalidMessage}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Label>Data Type</Label>
            <Form.Control
              name="type"
              as="select"
              className="form-select"
              value={currentlySelectedColumn.type}
              onChange={this.updateODataModel}
            >
              {dataTypeDropdownOptions}
            </Form.Control>
          </Form.Group>
          {maxLengthField}
          Is Nullable
          <Input
            id="nullable"
            name="nullable"
            checked={currentlySelectedColumn.nullable === true}
            label=""
            type="switch"
            className="odata-checkbox mb-3"
            onChange={this.updateODataModel}
          />
        </>
      ) : (
        <div className="no-odata-column-selected-message">
          Select a column you’d like to make edits to.
        </div>
      );

    return (
      <Form
        className={this.getComponentName()}
        onSubmit={(event) => this.updateColumns(event)}
      >
        <Row className="mb-3">
          <Col>
            <NavLink
              className="align-middle text-dark"
              to={{
                pathname: "/odata",
              }}
            >
              <i className="fa fa-arrow-left fa-lg back-arrow" />
            </NavLink>
            <h2 className="d-inline align-middle h3 ms-3">
              Edit OData Entity Set
            </h2>
          </Col>
          <Col>
            <Button
              color="primary"
              className="card-actions float-end"
              disabled={
                this.state.saveButtonDisabled ||
                this.state.tableAliasIsInvalid ||
                this.state.columnAliasIsInvalid ||
                !this.state.unsavedChanges
              }
            >
              <i className="fa fa-save fa-sm align-middle add-connection-icon no-pointer-event" />
              Save Changes
            </Button>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <FormGroup>
              <h4 className="card-title mb-2">{entityType}</h4>
              <div>{tableName}</div>
            </FormGroup>
            <Form.Group className="mb-0">
              <Label for="alias" className="font-weight-bold">
                Alias
              </Label>
              <Form.Control
                id="alias"
                name="alias"
                defaultValue={alias}
                onChange={this.updateAlias}
                isInvalid={this.state.tableAliasIsInvalid}
              />
              <Form.Control.Feedback type="invalid">
                {this.state.tableAliasIsInvalidMessage}
              </Form.Control.Feedback>
            </Form.Group>
          </CardBody>
        </Card>
        <Card className="edit-odata-column-card">
          <div className="odata-column-list-container">
            <div className="odata-column-list">
              <h4 className="odata-columns-title card-title mb-2">
                {entityTypeColumns}
              </h4>
              {columnTable}
            </div>
          </div>
          <div className="edit-odata-column-container">
            <div className="edit-odata-column">
              <h4 className="card-title mb-3">Edit Columns</h4>
              {editODataColumnBody}
            </div>
          </div>
        </Card>
      </Form>
    );
  }

  render() {
    const contents = this.state.loading ? (
      <Container fluid className="p-0">
        <Loader />
      </Container>
    ) : (
      this.renderEditTableCard()
    );

    return (
      <Container fluid className="p-0">
        <div hidden={!this.state.processingRequest}>
          <div className="loading-background" />
          <Spinner className="spinner-border loading-spinner" color="info" />
        </div>
        <PromptWithModal
          when={this.state.unsavedChanges}
          navigate={(path: string) => Navigate({ to: path })}
        />
        {contents}
      </Container>
    );
  }
}

export default withAPI(withRouter(EditTable));
